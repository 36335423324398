
import { defineComponent, ref } from 'vue';

import { Modal } from 'ant-design-vue';
import type { UploadProps } from 'ant-design-vue';

import MediaCards from '@/components/cards/MediaCards.vue';
import TableList from '@/components/tables/TableList.vue';
import axios from '@/utils/axios';
import { getVideoUrl } from '@/utils/mediaUrl';
const columns = [
  {
    title: 'File name',
    dataIndex: 'name',
    key: 'name',
    isList: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Video',
    dataIndex: 'mediaUrl',
    isList: true,
    key: 'mediaUrl',
  },
  {
    title: 'Scenario',
    dataIndex: 'scenario',
    key: 'scenario',
  },
];

export default defineComponent({
  name: 'UploadImage',
  components: {
    TableList,
    MediaCards,
  },
  props: {
    apiUrl: {
      type: String,
      required: true,
    },
    scenarioUrl: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const imageFileList = ref<UploadProps['fileList']>([]);
    const mediaCardRef = ref();
    const tableListRef = ref();

    const pivotCheck = ref(false);
    const pivotValue = ref(0);
    const maxPivotValue = ref(0);

    const tempVideoUrl = ref('');

    const onSelect = (e: string) => {
      tempVideoUrl.value = e;
    };

    const onLoadVideo = (e: any) => {
      maxPivotValue.value = e.target.duration.toFixed(2);
    };

    const onClick = async () => {
      const data = await mediaCardRef.value.submit();
      const { image, video, scenario } = data;
      const param = pivotCheck.value
        ? {
            videoFaceId: video[0].faces[0].id,
            imageFaceId: image[0].faces[0].id,
            name: image[0].uid,
            scenarioId: scenario.uid,
            pivot: pivotValue.value,
          }
        : {
            videoFaceId: video[0].faces[0].id,
            imageFaceId: image[0].faces[0].id,
            name: image[0].uid,
            scenarioId: scenario.uid,
          };

      const { status } = await axios.post(props.apiUrl, param);

      if (200 <= status && status < 300) {
        Modal.info({
          title: '요청 성공',
          content: '요청 성공했어요. 조금만 기다려보세요',
        });
        tableListRef.value.getApiData();
      } else {
        Modal.error({
          title: '요청 실패',
          content: '다시 한번 요청해보세요',
        });
      }
    };

    return {
      mediaCardRef,
      tableListRef,
      imageFileList,
      tempVideoUrl,
      pivotCheck,
      pivotValue,
      maxPivotValue,
      columns,
      // func
      getVideoUrl,
      onClick,
      onSelect,
      onLoadVideo,
    };
  },
});
